import React from 'react';

import axios from 'axios';

import { Link } from 'react-router-dom';

import { Form, Accordion, Icon } from 'semantic-ui-react';
import ProductSkus from './ProductSkus';

import applicationActionCreator, { actions } from '../../redux/actions/applicationActions';
import CenteredMidLayout from '../Components/CenteredMidLayout';
import IMComponent from '../Components/IMComponent';
import { Button, Header, Image, Modal } from 'semantic-ui-react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class Edit extends IMComponent {
    constructor(props) {
        super(props);

        this.loadProductInformation(props.match.params.productId);
    }

    onModelValidation = () => {
        return true;
    }

    loadProductInformation = function (id) {
        const self = this;
        if (!id)
            return;
        this.props.showLoading('LOAD_INFORMATION_PRODUCT');
        return axios.get('api/Product/' + id)
            .then(function (response) {
                if (response) {
                    self.setState({ model: response.data });
                }
            })
            .catch(function (e) {
                this.showToastError({ title: "Product", message: e.response.data })
            })
            .then(function () {
                self.props.hideLoading('LOAD_INFORMATION_PRODUCT');
            });
    }

    onClickSubmitHandler = () => {
        if (!this.props.match.params.productId)
            this.submitCreateProduct();
        else
            this.submitUpdateProduct();
    }

    submitCreateProduct = function () {
        this.props.showLoading('SUBMIT_PRODUCT');
        const self = this;
        return axios.post('api/Product', this.state.model)
            .then(function (response) {
                if (response) {
                    self.props.goToPage(`/product/edit/${response.data.productId}`);
                    self.showToastSuccess({ title: "Product", message: "Product was created successfully." })
                }
            })
            .catch(function (e) {
                self.showToastError({ title: "Product", message: e.response.data })
            })
            .then(function () {
                self.props.hideLoading('SUBMIT_PRODUCT');
            })
    }

    submitUpdateProduct = function () {
        this.props.showLoading('SUBMIT_PRODUCT');
        const self = this;
        return axios.put('api/Product', this.state.model)
            .then(function (response) {
                if (response) {
                    self.showToastSuccess({ title: "Product", message: "Product was updated successfully." });
                }
            })
            .catch(function (e) {
                self.showToastError({ title: "Product", message: e.response.data });
            })
            .then(function () {
                self.props.hideLoading('SUBMIT_PRODUCT');
            })

    }

    handleDataChange = (e) => {
        switch (e.target.name) {
            case 'name': this.setState({ productName: e.target.value });
                break;
            default:
                break;
        }
    }

    handleSkuDeleted = (sku) => {
        let product = this.state.model;
        let skus = product.productSkus;
        let newSkus = skus.filter(x => x.skuId != sku.skuId);
        product.productSkus = newSkus;
        this.setState({ model: product, validModel: true });
    };

    handleSkuAdded = (sku) => {
        let product = this.state.model;
        let skus = product && product.productSkus ? product.productSkus : [];
        skus.push(sku);
        product.productSkus = skus;
        this.setState({ model: product, validModel: true });

    };

    render() {
        let formClassName = this.props.editmode ? 'col-sm-6 col-md-6 col-sm-offset-3 col-md-offset-3 no__padding' : 'col-sm-6 col-md-6 col-sm-offset-3 col-md-offset-3';
        let saveChangeslabel = !this.props.match.params.productId ? "CREATE" : "UPDATE";
        const { validModel } = this.state;
        const { name, lastVersion, downloadLink, mainProgram, shopifyAlias, limitTokens } = this.state.model;
        const modalButton = <Form.Button content={"SKU Details"} fluid primary size="huge" />;
        return (
            <div className={formClassName}>

                <div className="ui grid">
                    <div className="four column row">
                        <div className="left floated column"><h1 className="headline-primary">Product</h1></div>
                        <div className="right floated column">
                            <Link to='/product' className="pull-right" >
                                <svg className="special__icon">
                                    <use xlinkHref="img/sprite/sprite.svg#svg--close-browser"></use>
                                </svg>
                            </Link></div>
                    </div>
                </div>
                <CenteredMidLayout>
                    <Form onSubmit={this.onClickSubmitHandler}>
                        <Form.Input value={name} name="name" onChange={this.onModelPropertyChange} label="Product Name"></Form.Input>
                        <Form.Input value={lastVersion} name="lastVersion" onChange={this.onModelPropertyChange} label="Last Version"></Form.Input>
                        <Form.Input value={downloadLink} name="downloadLink" onChange={this.onModelPropertyChange} label="Download link"></Form.Input>
                        <Form.Input value={mainProgram} name="mainProgram" onChange={this.onModelPropertyChange} label="Main Program"></Form.Input>
                        <Form.Input value={shopifyAlias} name="shopifyAlias" onChange={this.onModelPropertyChange} label="Shopify Alias"></Form.Input>
                        <Form.Input value={limitTokens}  name="limitTokens" onChange={this.onModelPropertyChange} label="Limit Tokens"></Form.Input>
                        <Form.Button content={saveChangeslabel} fluid primary size="huge" disabled={!validModel} />

                    </Form>
                    <br />
                    <Modal trigger={modalButton} closeIcon size='large'>
                        <Modal.Header>Product SKUs</Modal.Header>
                        <Modal.Content image>
                            <ProductSkus skus={this.state.model.productSkus ? this.state.model.productSkus : []} onDeleteSku={this.handleSkuDeleted} onAddSku={this.handleSkuAdded} />
                        </Modal.Content>
                    </Modal>

                </CenteredMidLayout>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        showLoading: function (caller) { return dispatch(applicationActionCreator.showLoading(caller)) },
        hideLoading: function (caller) { return dispatch(applicationActionCreator.hideLoading(caller)) },
        successMessage: function (message) { return dispatch(applicationActionCreator.notification.success(message)) },
        errorMessage: function (message) { return dispatch(applicationActionCreator.notification.error(message)) },
    }
}

export default connect(null, mapDispatchToProps)(Edit);